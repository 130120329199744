import React from "react";
import Header from "../Components/nav";
import Footer from "../Components/footer";
import Cancellation from "../Components/cancellation";

const CancellationRoute = () => {
  return (
    <div>
      <Header />
      <Cancellation />
      <Footer />
    </div>
  );
};

export default CancellationRoute;
