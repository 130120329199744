import React from "react";
import Header from "../Components/nav";
import Footer from "../Components/footer";

import Terms from "../Components/terms";

const TermsRoute = () => {
  return (
    <div>
      <Header />
      <Terms />
      <Footer />
    </div>
  );
};

export default TermsRoute;
